export interface IUserProfile {
    email: string;
    is_active: boolean;
    is_superuser: boolean;
    full_name: string;
    id: string;
    credits: number;
}

export interface IUserProfileUpdate {
    email?: string;
    full_name?: string;
    password?: string;
    is_active?: boolean;
    is_superuser?: boolean;
}

export interface IUserProfileCreate {
    email: string;
    full_name?: string;
    password?: string;
    is_active?: boolean;
    is_superuser?: boolean;
}

export interface ISession {
    title: string;
    description: string;
    redirect_url: string;
    state: string;
    admin_lang: string;
    display_lang: string;
    id: string;
    created_date: string;
}

export interface ISessionData {
    title: string;
    description: string;
    redirect_url: string;
    redirect_url_use_params: boolean;
    disqualified_should_redirect: boolean;
    disqualified_redirect_url: string;
    screening_text: string;
    id: string;
    questions_with_data: IQuestionData[];
    state: string;
    admin_lang: string;
    display_lang: string;
    chat_style?: object;
    chat_logo?: string;
    chat_logo_enabled: boolean;
    p_started?: number;
    p_finished?: number;
    p_disqualified?: number;
    ext_p_started?: number;
    ext_p_finished?: number;
    median_time?: number;
    lucid_survey_nr?: string;
    lucid_quota?: number;
    lucid_country_lang_id?: number;
    collect_email: boolean;
    collect_email_text: string;
    completion_code: boolean;
    completion_code_text: string;
    completion_code_unique: boolean;
    nominal_questions?: INominalQuestion[];
}

export interface ISessionUpdate {
    title?: string;
    description?: string;
    redirect_url?: string;
    redirect_url_use_params?: boolean;
    state?: string;
    admin_lang?: string;
    display_lang?: string;
    collect_email?: boolean;
    collect_email_text?: string;
    chat_style?: object;
    chat_logo_enabled?: boolean;
    completion_code?: boolean;
    completion_code_text?: string;
    completion_code_unique?: boolean;
    disqualified_should_redirect?: boolean;
    disqualified_redirect_url?: string;
    screening_text?: string;
}

export interface ISessionCreate {
    title: string;
    description?: string;
    redirect_url?: string;
    questions: IQuestionCreate[];
}

export interface IQuestion {
    title: string;
    context: string;
    id: string;
    type: QuestionTypes;
    show_estimate: boolean;
    show_estimate_after: boolean;
}

export interface INominalQuestion {
    id: string;
    title: string;
    contracts: IContractOrdered[];
}

export interface IBinarySuggestion {
    suggestion: string;
}

export interface IBinarySuggestionData {
    topic: string;
}

export interface IQuestionImage {
    id: string;
    name: string;
    url?: string;
    caption?: string;
}

export interface IQuestionData {
    title: string;
    context: string;
    type: QuestionTypes;
    id: string;
    disagreement: number;
    responsiveness: number;
    show_estimate: boolean;
    show_estimate_after: boolean;
    contracts?: string[];
    data: IScenarioData[] | IBinaryData | INominalQuestionData[];
    images?: IQuestionImage[];
    videos?: IQuestionVideo[];
}

export interface IRepReason {
    rep_reason: string;
    contract: IContract;
}

export interface IEstimate {
    p_estimate: number;
    estimate: number;
    lower_rep_reason?: IRepReason;
    upper_rep_reason?: IRepReason;
    is_initial: boolean;
}


export interface IDrivingRepReason {
    reason: string;
    avg_change: number;
}


export interface IBinaryData {
    created: Date[];
    estimates: IEstimate[];
    for_reason?: IDrivingRepReason;
    against_reason?: IDrivingRepReason;
}

export interface IScenarioData {
    contract: IContract;
    created: Date[];
    estimates: IEstimate[];
    for_reason?: IDrivingRepReason;
    against_reason?: IDrivingRepReason;
}

export interface INominalQuestionData {
    contract: IContract;
    answer_count: number;
}
export interface IContract {
    text: string;
    question_id: string;
    language_id: number;
    contract_uuid?: string;
}

export interface IContractOrdered {
    text: string;
    contract_uuid: string;
}

export interface IQuestionUpdate {
    title?: string;
    context?: string;
    show_estimate?: boolean;
    show_estimate_after?: boolean;
    contracts?: IContract[];
    randomize_qs?: boolean;
}

export interface IQuestionImageUpdate {
    caption?: string;
}

export interface IQuestionCreate {
    title: string;
    context: string;
    type: QuestionTypes;
    contracts?: string[];
    randomize_qs?: boolean;
}

export enum QuestionTypes {
    'binary' = 'binary',
    'scenario' = 'scenario',
    'open' = 'open',
    'nominal' = 'nominal',
}

export interface IReasonCreate {
    reason: string;
}


export interface ISlackAuth {
    nonce: string;
}

export enum IPointEstimates {
    'highly-unlikely',
    'likely',
    'toss-up',
    'unlikely',
    'highly-likely',
}

export interface IParticipantWithResponse {
    id: string;
    question: string;
    contract?: IContract;
    open_ended_response?: string;
    p_estimate: number;
    reason?: string;
    reason_info_score: number;
    followup_q?: string;
    followup_a?: string;
    upper_rep_reason?: IRepReason;
    lower_rep_reason?: IRepReason;
    p_estimate_update?: number;
    nominal_data?: object;
}

export interface IQuestionWithParticipants {
    id: string;
    participants: IParticipantWithResponse[];
}

export interface IQuestionWithParticipantCount {
    id: string;
    type: QuestionTypes;
    participants_count: number;
}

export interface IQuestionTopicsDriversData {
    label: string;
    value: number;
}

export interface IQuestionTopicsLabel {
    label: string;
    count: number|null;
}

export interface IQuestionTopicsData {
    id: string;
    classifier_id: string;
    labels: IQuestionTopicsLabel[];

    first_contract: string;
    second_contract: string;

    topics_1: IQuestionTopicsDriversData;
    topics_2: IQuestionTopicsDriversData;

    drivers_1: IQuestionTopicsDriversData;
    drivers_2: IQuestionTopicsDriversData;
}


export interface ISessionResponseData {
    questions: IQuestionWithParticipants[];
}

export interface ISessionResponses {
    questions: IQuestionWithParticipantCount[];
}

export interface ISessionTopics {
    questions: IQuestionWithTopics[];
}

export interface IQuestionWithTopics {
    id: string;
    topics: IQuestionTopicsData;
}

export interface IBinaryTopic {
    centroid_reason: string;
    dominant_estimate: string;
    cluster_reasons: string[];
}

export interface IScenarioTopic {
    scenario: string;
    main_centroid: string;
    sub_centroids: string[];
}

export interface IStudyData {
    data: ISessionData;
    responses: ISessionResponses;
    topics: ISessionTopics;
}

export interface IQuestionOrderMapping {
    id: string;
    order_idx: number;
}

export interface IBetaSigneeCreate {
    full_name: string;
    email: string;
    occupation: string;
    usage_reason: string;
}

export interface IBetaSignee {
    full_name: string;
    email: string;
    occupation: string;
    usage_reason: string;
    id: string;
    created: Date;
    approved: boolean;
    date_approved?: Date;
}

export interface ITransaction {
    id: string;
    user_id: string;
    transferred: number;
    method: string;
    note?: string;
    created: Date;
}

export interface ILucidSettings {
    quotaCPI_FR_1Q: number;
    quotaCPI_FR_2Q: number;
    quotaCPI_FR_3Q: number;
    quotaCPI_SE_1Q: number;
    quotaCPI_SE_2Q: number;
    quotaCPI_SE_3Q: number;
    quotaCPI_CN_1Q: number;
    quotaCPI_CN_2Q: number;
    quotaCPI_CN_3Q: number;
}

export interface IProlificSettings {
    quotaCPI_UK_1M: number;
    quotaCPI_US_1M: number;
}

export enum CountryLangIds {
    'UK' = 8,
    'US' = 9,
    'CN' = 1,
    'SE' = 23,
}

export interface IRecruitParams {
    country_lang_id: CountryLangIds;
    quota: number;
    age_range: number[];
    gender_balance: number;
    number_of_questions?: number;
}

export interface IExternalParticipantsCount {
    male_participants: number;
    female_participants: number;
}
export interface ILucidAuidienceEstimate {
    feasibility: number;
    price: number;
    sample_probability: number;
}

export interface IQuestionVideoCreate {
    name?: string;
    embed_code?: string;
    caption?: string;
    question_id: string;
}

export interface IQuestionVideo {
    id?: string;
    name: string;
    embed_code: string;
    caption?: string;
    question_id?: string;
}

