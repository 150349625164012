const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let envSessionLinkBase = '';

if (env === 'sandbox') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_SANDBOX}`;
  envSessionLinkBase = 'https://study.sandbox.masspredict.io/';
} else if (env === 'prod') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  envSessionLinkBase = 'https://study.masspredict.io/';
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
  envSessionLinkBase = 'http://localhost:8091/';
}

export const apiUrl = envApiUrl;
export const publicSessionLinkBase = envSessionLinkBase;
export const resultsLinkBase = envApiUrl + '/public/study/';
export const appName = 'masspredict.io';
export const googleClientId = `${process.env.VUE_APP_GOOGLE_CLIENT_ID}`;
export const slackClientId = `${process.env.VUE_APP_SLACK_CLIENT_ID}`;
export const gitHubClientId = `${process.env.VUE_APP_GITHUB_CLIENT_ID}`;
export const sentryDsn = `${process.env.VUE_APP_SENTRY_DSN}`;
export const environment = env;
