import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ISessionDataState } from './state';

const defaultState: ISessionDataState = {
  data: {
    title: '',
    description: '',
    redirect_url: '',
    redirect_url_use_params: false,
    id: '',
    state: '',
    questions_with_data: [],
    admin_lang: '',
    display_lang: '',
    collect_email: false,
    collect_email_text: '',
    chat_logo_enabled: false,
    completion_code: false,
    completion_code_text: '',
    completion_code_unique: false,
    disqualified_should_redirect: false,
    disqualified_redirect_url: '',
    screening_text: '',
  },
  responses: {
    questions: [],
  },
  topics: {
    questions: [],
  },
  response_data: {
    questions: [],
  },
};

export const SessionDataModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
