import { ISessionsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';

export const getters = {
  sessions: (state: ISessionsState) => state.sessions,
  oneSession: (state: ISessionsState) => (sessionId: string) => {
    const filteredSessions = state.sessions.filter((session) => session.id === sessionId);
    if (filteredSessions.length > 0) {
      return { ...filteredSessions[0] };
    }
  },
  latestSession: (state: ISessionsState) => {
    return state.sessions[state.sessions.length-1];
  },
  sessionsByState: (state: ISessionsState) => (sessionState: string) => {
    const filteredSessions = state.sessions.filter((session) => session.state === sessionState);
    if (filteredSessions.length > 0) {
      return filteredSessions;
    }
  },
};

const { read } = getStoreAccessors<ISessionsState, IState>('');

export const readOneSession = read(getters.oneSession);
export const readSessions = read(getters.sessions);
export const readLatestSession = read(getters.latestSession);
export const readSessionsByState = read(getters.sessionsByState);
