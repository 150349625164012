import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.shades.white,
        secondary: colors.shades.black,
        accent: colors.grey.lighten2,
        error: colors.red.accent3,
        lightgray: colors.grey.lighten3
      },
      dark: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        accent: '#1e1e1e',
        error: colors.red.accent3,
        lightgray: '#2a2a2a',
        sidenav: '#181818',
        btncolor: '#303931'
      }
    },
  },
});
